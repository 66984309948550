declare global {
  interface Window { Stimulus: any; }
}

import { Application } from "@hotwired/stimulus"
import { Autocomplete } from "stimulus-autocomplete"
// import HideableController from "./controllers/hideable_controller"

window.Stimulus = Application.start()

window.Stimulus.register("autocomplete", Autocomplete)
// window.Stimulus.register("hideable", HideableController)
